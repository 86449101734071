// One column with
$column:        8.33333333%;

// Width container
$container:     1125px;

// Column step
//$col-step:      32px
$col-step:      0;

// Media queries breakpoints
$tiny:          576px;
$small:         768px;
$medium:        992px;
$large:         1200px;
$extraLarge:    1600px;
