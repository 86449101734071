@import "../commons/variables";
@import "../mixins/mixins";

.input {
  & {
    position: relative;
    display: inline-block;
    margin-top: 40px;
    width: 100%;
    padding-top: 1em;
    border: none;
    outline: none;
    font-weight: normal;
    font-size: $sizeText;
    color: $colorText;
    line-height: 1.1em;
    background-color : transparent;
    vertical-align: top;

    @include respond-to-max(740px) {
      margin-top: 20px;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__field {
    position : relative;
    display : block;
    width : 100%;
    height : 100%;
    padding-bottom: 0.5em;
    font-size: $sizeText;
    color : $colorText;
    border : none;
    border-radius : 0;
    background-color : transparent;
    resize : none;
    -webkit-appearance : none; /* for box shadows to show on iOS */
    z-index : 10;
  }

  &__field:focus {
    outline : none;
  }

  &__label {
    display : inline-block;
    position : absolute;
    top : 0;
    left : 0;
    bottom : 0;
    padding : 0;
    width : 100%;
    font-size : $sizeLabelText;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing : grayscale;
    user-select : none;
    cursor : text;
    pointer-events: none;

    &::before,
    &::after {
      content : '';
      position : absolute;
      bottom : 0;
      left : 0;
      width : 100%;
      height : 1px;
    }
    &::before {
      transform-origin: 0 50%;
      transform : scale(0, 1);
      border-bottom: 1px solid $borderNoneActive;
      transition : transform 0.3s ease;
      z-index : 2;
    }

    &::after {
      border-bottom: 1px solid $borderActive;
      z-index: 1;
    }


    &-content {
      position : relative;
      top: 1em;
      display : block;
      color : $colorLabelText;
      width : 100%;
      transition: transform 0.2s ease,
                  font-size 0.2s ease,
                  opacity 0.2s ease;
    }

  }

  .input__field--akira:focus ~ .input__label::before,
  &.input--filled .input__label::before,
  .input--filled ~ .input__label::before {
    transform : scale(1, 3);
  }

  .input__field:focus ~ .input__label .input__label,
  &.input--filled .input__label-content,
  .input-filled ~ .input__label .input__label-content {
    opacity : 0.7;
    font-size : 14px;
    transform : translateY(-1.4rem);
  }

  /* Error */
  &.error .input__label::after {
    transform: scale(1,3);
    border-bottom: 1px solid $colorError;
  }
  p.error-message {
    position : absolute;
    bottom : -1.7em;
    height: 1.5em;
    line-height: 1.1em;
    width : 100%;
    font-size : 0.7rem;
    color : $colorError;
  }
}