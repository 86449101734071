@import "../../mixins/mixins";
@import "../../commons/variables";

.b-prod-item {
  position: relative;
  display: flex;
  height: 640px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @include respond-to-max($medium) {
    height: 50vw;
  }

  @include respond-to-max($tabletPortrait) {
    max-height: 500px;
  }

  @include respond-to-max($tiny) {
    height: 100vw;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s;
  }

  &__heading {
    position: relative;
    text-transform: capitalize;
    text-align: center;
    color: #ffffff;
    z-index: 1;
    pointer-events: none;
    transition: opacity 0.4s 0.2s,
                transform 0.4s;

    @include respond-to-max(1100px) {
      font-size: 50px;
    }

    @include respond-to-max(950px) {
      font-size: 40px;
    }
  }
  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-position: center bottom;
    background-repeat: no-repeat;
    transform-origin: 100% bottom;
    z-index: 0;
    transition: opacity 0.5s,
                transform 0.8s;
  }
  &__hide{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 40px;
    opacity: 0;
    transform: translate(0, 50px);
    pointer-events: none;
    z-index: 3;
    transition: opacity 0.3s,
                transform 0.6s 0.2s;

    &-content {
      margin: auto;
      text-align: center;

      .hrc-regular {
        color: #ffffff;
      }

      .hrc-btn_transparent {
        margin: 30px auto 0;
      }
    }
  }
  &:hover {
    &:after {
      opacity: 1;
    }

    .b-prod-item {
      &__heading {
        transform: translate(0, -50px);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.1s,
                    transform 0.4s;
      }
      &__hide {
        animation: shoBlock 0.4s forwards;
        //opacity: 1;
        //transform: translate(0, 0);
        //pointer-events: auto;
        //transition: opacity 0.2s 0s,
        //            transform 0.4s 0s;
      }
    }
  }
}

.mobile-iOS {
  .b-prod-item:hover {
    .b-prod-item__hide {
      pointer-events: none;
    }
  }
}

.b-prod-1 {
  position: relative;
  overflow: hidden;
  padding: 120px 20px 180px;
  background-color: $darkColor;

  @include respond-to-max($medium) {
    padding: 70px 20px 100px;
  }

  @include respond-to-max($mobileLandscape) {
    padding: 50px 20px 30px;
  }

  &__content {
    flex: 1 0 auto;

    @include respond-to-max($medium) {
      position: relative;
      z-index: 1;
    }
  }

  &__heading {
    margin-bottom: 20px;
    color: #BE7355;
    text-transform: capitalize;
    transition: opacity 0.4s,
                transform 0.5s;

    @include respond-to-max(1270px) {
      font-size: 200px;
    }

    @include respond-to-max(1100px) {
      font-size: 160px;
    }

    @include respond-to-max($medium) {
      margin-bottom: 70px;
      font-size: 100px;
    }

    @include respond-to-max($mobileLandscape) {
      font-size: 64px;
    }

    @include respond-to-max($tabletPortrait) {
      text-align: center;
    }
  }
  &__regular {
    max-width: 520px;
    margin-bottom: 50px;
    color: #ffffff;
    transition: opacity 0.4s 0.15s,
                transform 0.5s 0.2s;

    @include respond-to-max($medium) {
      max-width: 350px;
    }

    @include respond-to-max($tabletPortrait) {
      max-width: 100%;
      text-align: center;
    }
  }
  &__button {
    border-width: 3px;
    transition: opacity 0.4s 0.25s,
                transform 0.5s 0.3s;

    @include respond-to-max($tabletPortrait) {
      display: block;
      margin: 0 auto;
    }

    &:hover {
      color: $darkColor !important;
    }
  }

  &__poster {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-size: contain;
    background-position: calc(50% + 420px) bottom;
    background-repeat: no-repeat;
    transition: opacity 0.5s,
    transform 0.8s;

    @include respond-to-max(1400px) {
      background-position: calc(100% + 200px) bottom;
    }

    @include respond-to-max($medium) {
      background-size: auto 85%;
      background-position: calc(100% + 80px) bottom;
      z-index: 0;
    }
  }

  &.start {
    .b-prod-1 {
      &__heading,
      &__regular,
      &__button {
        transform: translate(0, 50px);
        opacity: 0;
      }
      &__poster {
        transform: translate(100px, 0);
        opacity: 0;
      }

    }
  }
}

.b-prod-2 {
  .b-prod-item__poster {
    background-position: -60px bottom;

    @include respond-to-max($medium) {
      background-position: -60px 10vw;
      background-size: auto 90%;
    }

    @include respond-to-max($mobileLandscape) {
      background-position: -60px 20vw;
      background-size: auto 80%;
    }
  }

  &:hover {
    .b-prod-item {
      &__poster {
        transform: scale(1.05);
      }
    }
  }
}
.b-prod-3 {
  .b-prod-item__poster {
    transform-origin: 0 bottom;
    background-position: -25% 135%;

    @include respond-to-max($medium) {
      background-size: auto 50%;
    }
  }
  &:hover {
    .b-prod-item {
      &__poster {
        transform: scale(1.05);
      }
    }
  }
}

.b-prod-4,
.b-prod-5,
.b-prod-6 {
  display: block;
  height: 450px;

  .b-prod-item__hide {
    padding: 40px 20px;

    @include respond-to-max($mobileLandscape) {
      padding: 40px;
    }
  }

  .b-prod-item__hide-content {
    margin: auto 0;
    width: 100%;

    .hrc-btn_transparent {
      margin-top: 0;
    }
  }

  @include respond-to-max($medium) {
    height: 33.333vw;
  }

  @include respond-to-max($small) {
    flex: 0 0 33.3333%;
  }

  @include respond-to-max($mobileLandscape) {
    flex: 0 0 100%;
  }

  @include respond-to-max($mobileLandscape) {
    height: 200px;
  }

  .b-prod-item__heading {
    margin: 50px auto 0;

    @include respond-to-max($tabletPortrait) {
      margin: 30px auto 0;
    }

    @include respond-to-max($mobileLandscape) {
      margin: 20px auto 0;
    }
  }

  &:hover {
    .b-prod-item__poster {
      transform: translate(0, 0) scale(1.05);
    }
  }
}

.b-prod-4 {
  background-color: #4A575E;

  .b-prod-item__poster {
    background-position: center bottom;
    background-size: auto 60%;

    @include respond-to-max(1600px) {
      background-size: contain;
    }

    @include respond-to-max($tabletLandscape) {
      background-size: auto 40%;
    }

    @include respond-to-max($mobileLandscape) {
      background-size: auto 70%;
    }
  }
}

.b-prod-5 {
  background-color: #3C4A4F;

  .b-prod-item__poster {
    background-position: 40% bottom, 50% 150px;

    @include respond-to-max(1400px) {
      background-size: auto 65%;
    }

    @include respond-to-max($medium) {
      background-size: auto 65%;
      background-position: 40% bottom, 50% 15vw;
    }

    @include respond-to-max($mobileLandscape) {
      background-size: auto 70%;
      background-position: 40% bottom, 50% 100px;
    }
  }
}

.b-prod-6 {
  background-color: #424245;

  .b-prod-item__poster {
    transform-origin: center bottom;
    background-size: auto 48%;
  }

  &:hover {
    .b-prod-item__poster {

    }
  }
}


@keyframes shoBlock {
  0% { opacity: 0; transform: translate(0, 50px); pointer-events: none; }
  50% { opacity: 1; }
  100% { opacity: 1; transform: translate(0, 0); pointer-events: auto; }
}