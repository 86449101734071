@import "../mixins/mixins";
@import "../commons/variables";

.l-social {
  position: fixed;
  right: 0;
  bottom: 15vh;
  box-shadow: 1px -2px 50px rgba(0, 0, 0, 0.3);
  z-index: 2;

  @include respond-to-max($tabletLandscape) {
    display: none;
  }

  a {
    display: block;
    height: 2em;
    width: 2em;
    font-size: 30px;
    background-color: $darkColor;
  }
}