@import "../mixins/mixins";
@import "../commons/variables";

.l-footer {
  background: #1b2037;
}

.b-footer {
  &__top {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #3c485d;
  }
  &__col {
    padding: 60px 40px 30px 60px;
    flex: 1;
    border-right: 1px solid #3c485d;

    @include respond-to-max(1100px) {
      padding: 60px 30px 30px 40px;
    }

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
      border-right: none;

      @include respond-to-max($tabletPortrait) {
        order: 2;
        border-top: 1px solid #3c485d;
      }
    }

    @include respond-to-max(900px) {
      padding: 20px !important;
    }

    .b-footer__heading {
      margin-bottom: 0.83em;

      @include respond-to-max($tabletPortrait) {
        margin-bottom: 0;
        width: 100%;
        text-align: center;
      }
    }

    &:not(.col_small) {

      @include respond-to-max(900px) {
        order: 1;
      }
      .b-footer__list {
        columns: 2;

        @include respond-to-max(900px) {
          column-gap: 125px;
        }

        @include respond-to-max($tabletPortrait) {
          columns: auto;
        }
      }

      @include respond-to-max(900px) {
        flex: 0 0 100%;
        border-right: none;
        border-top: 1px solid #3c485d;
      }
    }

    &.col_small {
      flex: 0 0 29%;

      @include respond-to-max(900px) {
        flex: 0 0 50%;
      }
    }

    @include respond-to-max($mobileLandscape) {
      padding: 60px 20px 30px 20px;
    }

    @include respond-to-max($tabletPortrait) {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 100% !important;
      padding: 20px 10px;
      border-right: none;
    }
  }
  &__heading {
    color: #fbfbf2;
  }
  &__list {
    @include respond-to-max($tabletPortrait) {
      text-align: center;
    }

    li {

      &[class*='icon-'] {
        padding-left: 40px;
        background-position: left center;
      }

      &[class*='icon-'] + li {
        padding-left: 40px;

        @include respond-to-max($tabletPortrait) {
          &:last-child {
            padding-left: 0;
            text-align: center;
          }
        }
      }

      &.icon-message {
        background-size: 25px auto;
      }

      &.icon-phone {
        background-size: 20px auto;
      }

      button,
      a {
        line-height: 60px;
        font-size: 18px;
        color: #ffffff;
        text-transform: uppercase;

        &[href*="mailto"] {
          text-transform: lowercase;
        }

        &:hover {
          color: darken(#ffffff, 20%);
        }

        @include respond-to-max(1200px) {
          font-size: 16px;
        }

        @include respond-to-max(1100px) {
          font-size: 14px;
        }
      }
    }
  }
  &__search {
    display: flex;
    align-items: center;
    margin-top: 50px;
    font-size: 16px;

    @include respond-to-max($tabletPortrait) {
      display: none;
    }

    .input {
      margin-top: 0;
    }

    .icon-search {
      display: block;
      height: 2em;
      width: 2em;
      margin-right: 1em;
    }

    .input__field {
      display: block;
      height: 2em;
      width: 100%;
      font-size: inherit;
      color: #ffffff;

      @include placeholder {
        font-size: 16px;
        color: #737373;
      }
    ;
    }
  }
  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    @include respond-to-max(970px) {
      padding: 20px;
    }

    @include respond-to-max($tabletPortrait) {
      padding: 20px 0 0;
    }

    .hrc-btn__social {
      @include respond-to-max(970px) {
        font-size: 24px;
      }
    }

    &-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include respond-to-max($tabletPortrait) {
        flex: 0 0 100%;
        padding-bottom: 20px;
        justify-content: center;
      }

      &:first-child {
        @include respond-to-max($tabletPortrait) {
          padding-bottom: 30px;
          border-bottom: 1px solid #3c485d;
        }
      }

      &:last-child {
        @include respond-to-max($tabletPortrait) {
          padding-top: 20px;
        }

        @include respond-to-max($mobileLandscape) {
          flex-direction: column;
        }
      }

      .hrc-regular {
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;

        @include respond-to-max(1150px) {
          font-size: 14px;
        }

        @include respond-to-max(900px) {
          font-size: 12px;
        }

        @include respond-to-max($tabletPortrait) {
          text-align: center;
        }
      }

      a.hrc-regular:hover {
        color: darken(#ffffff, 20%);
      }

      span.hrc-regular {
        margin-left: 30px;

        @include respond-to-max($mobileLandscape) {
          margin-left: 0;
          margin-top: 10px;
        }

        @include respond-to-max($mobilePortrait) {

        }
      }
    }

    .b-footer__heading {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 700;
      margin-right: 30px;

      @include respond-to-max(980px) {
        margin-right: 15px;
      }

      @include respond-to-max($tabletPortrait) {
        text-align: center;
        margin-right: 0;
        margin-bottom: 20px;
        flex: 0 0 100%;
      }
    }
  }
  &__social-group {
    display: flex;

    li {
      margin: 0 10px;
    }
  }

  .input__label {
    &::before {
      border-bottom: 1px solid #ffffff;
    }
    &::after {
      display: none;
    }
  }

  .input__field--akira:focus + .input__label::before,
  &.input--filled .input__label::before,
  .input--filled + .input__label::before {
    transform: scale(1, 1);
  }
}