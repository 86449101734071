@mixin respond-to-max($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}

@mixin respond-from-min($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

@mixin respond-from-to($mediaFrom, $mediaTo) {
  @media only screen and (min-width: $mediaFrom) and (max-width: $mediaTo) {
    @content;
  }
}

@mixin height-to-max($media) {
  @media only screen and (max-height: $media) {
    @content;
  }
}

@mixin height-from-min($media) {
  @media only screen and (min-height: $media) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }

  &[placeholder] {
    text-overflow: ellipsis;
  }
  &::-moz-placeholder {
    text-overflow: ellipsis;
  }
  &:-moz-placeholder {
    text-overflow: ellipsis;
  }
  &:-ms-input-placeholder {
    text-overflow: ellipsis;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus::-moz-placeholder {
    color: transparent;
  }
  &:focus:-moz-placeholder {
    color: transparent;
  }
  &:focus:-ms-input-placeholder {
    color: transparent;
  }

  &::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity .3s ease;
  }
  &::-moz-placeholder {
    opacity: 1;
    transition: opacity .3s ease;
  }
  &:-moz-placeholder {
    opacity: 1;
    transition: opacity .3s ease;
  }
  &:-ms-input-placeholder {
    opacity: 1;
    transition: opacity .3s ease;
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity .3s ease;
  }
  &:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity .3s ease;
  }
  &:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity .3s ease;
  }
  &:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity .3s ease;
  }
}

@mixin scroll-bar($width: 0px, $height: 0px, $color-track: #cecece, $color-thumb: darken($color-track, 70%)) {
  &::-webkit-scrollbar {
    border-radius: $width / 2.5;
    height: $height;
    width: $width;
  }
  &::-webkit-scrollbar-track {
    border-radius: $width / 2.5;
    background-color: $color-track;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $width / 2.5;
    background-color: $color-thumb;
  }
}

@mixin hint {
  position: relative;
  @content;

  &:before,
  &:after {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: attr(data-hint-title);
  }
  &:before {
    display: block;
    content: '';
  }

  &:hover {
    &:before,
    &:after {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@mixin after-or-before {
  display: block;
  content: '';
  @content;
}

@mixin after-or-before-over-block {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  @content;
}
