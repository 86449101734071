@import "../plugs/equalize/vars";

//colors
$darkColor: #2D2F38;
$redActiveColor: #CF0409;
$grayish: #9497A6;
$headerBgColor: #F5F6FA;

// Parameters

// HEADER
$heightHeader: 140px;
$smallHeightHeader: 88px;
$maxHeightHeader: 140px;
$tabletHeader: 60px;
$mobileHeader: 48px;

// PAGE
$maxWidth: 1900px;

// breakpoints
$tabletLandscape: 1024px;
$tabletPortrait: 768px;
$mobileLandscape: 740px;
$mobilePortrait: 500px;

// inputs
$colorText: #40363e;
$colorLabelText: #8D99AE;
$sizeLabelText: 16px;
$sizeText: 18px;

$borderNoneActive: #1B2037;
$borderActive: #8D99AE;
$colorError: #FF0000;


// Translate to mobile menu
$menuMobilePoint: $tabletLandscape - 1px;