$icomoon-font-path: "../fonts" !default;

$icon-glyph-curtains: "\e90d";
$icon-glyph-linens: "\e90e";
$icon-glyph-napkin-stand: "\e90f";
$icon-glyph-restaurant-table: "\e910";
$icon-glyph-arrow: "\e90c";
$icon-glyph-armchair: "\e900";
$icon-glyph-bra: "\e901";
$icon-glyph-chair: "\e902";
$icon-glyph-cupboard: "\e903";
$icon-glyph-lamp: "\e904";
$icon-glyph-napkins: "\e905";
$icon-glyph-nightstand: "\e906";
$icon-glyph-plate: "\e907";
$icon-glyph-sofa: "\e908";
$icon-glyph-spoons: "\e909";
$icon-glyph-table: "\e90a";
$icon-glyph-towel: "\e90b";