@import '../mixins/mixins';
@import '../commons/variables';
@import "../partials/setNumberInInput";
@import "../partials/cutting-icons";

.popup__overlay-open {
  .l-popups-section {
    opacity: 1;
    pointer-events: auto;
  }
}

.l-popups-section {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  background-color: rgba(45, 47, 56, 0.8);
  opacity: 0;
  pointer-events: none;
  z-index: 103;
  transition: opacity 0.3s;
  overflow: auto;

  @include height-to-max(600px) {
    padding: 0;
  }

  @include respond-to-max($mobileLandscape) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.popup {

  &__item {
    position: relative;
    margin: auto;
    max-width: 570px;
    opacity: 0;
    display: none;
    background-color: #FFFFFF;
    pointer-events: none;

    &.popup__content-open {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }

    &.submit-state {
      .popup__inner {
        display: none;
      }

      .popup__submit-state {
        display: block;
      }
    }
  }

  &__header {
    position: relative;
    padding: 40px 60px;
    background-color: $darkColor;

    @include respond-to-max($mobilePortrait) {
      padding: 15px 40px 15px 20px;
    }

    * {
      text-align: center;
      color: #FFFFFF;

      @include respond-to-max($mobilePortrait) {
        text-align: left;
      }
    }
  }

  &__close {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 2em;
    width: 2em;
    font-size: 18px;
    opacity: 0.5;
    transition: opacity 0.2s;
    z-index: 1;

    @include respond-to-max($mobilePortrait) {
      top: 50%;
      transform: translate(0, -50%);
      font-size: 12px;
    }

    &:before,
    &:after {
      position: absolute;
      top: calc(50% - 1em);
      content: '';
      left: 0;
      height: 1em;
      width: 100%;
      font-size: 0.1em;
      background-color: #FFFFFF;
      transform-origin: 50% 50%;
    }

    &:hover {
      opacity: 1;
    }

    &:before {
      transform: rotate(45deg) scale(1.3);
    }

    &:after {
      transform: rotate(-45deg) scale(1.3);
    }
  }

  &__inner {

  }

  &__submit-state {
    display: none;
    padding: 60px;

    &-icon {
      i {
        display: block;
        height: 80px;
        width: 80px;
        margin: 0 auto 40px;
        background-size: auto 50%;
        border: 1px solid #1C8A49;
        border-radius: 50%;
      }
    }

    .hrc-lead {
      margin-bottom: 80px;
      text-align: center;
    }

    .hrc-btn_transparent {
      width: 100%;
      max-width: 100%;
      height: 60px;
      line-height: 56px;

      &:first-child {
        margin-bottom: 20px;
      }
    }

  }

  &__title {
    display: flex;
    min-height: 59px;
    flex-wrap: wrap;
    background-color: #EFEFEF;

    &-category{
      display: flex;
      align-items: center;
      margin: 0 15px;
      text-align: center;

      @include respond-to-max($mobilePortrait) {
        margin: 0 auto;
        padding: 0 15px;
      }
    }

    &-icons {
      padding: 30px 10px;
      font-size: 54px;

      @include respond-to-max($mobileLandscape) {
        padding: 15px 5px;
        font-size: 24px;
      }

      i {
        padding: 0 15px;
        color: #BE7355;
      }
    }

    .side_left {
      direction: rtl;

      @include respond-to-max($mobilePortrait) {
        display: none;
      }
    }

    .side_right {

      @include respond-to-max($mobilePortrait) {
        display: none;
      }
    }

    &-img {
      //display: flex;
      background-color: #ffffff;

      @include respond-to-max($mobilePortrait) {
        display: block;
        padding: 10px 5px;
        width: 100%;
        text-align: center;
      }

      img {
        margin: auto;
        padding: 10px 0;
        max-width: 170px;
        max-height: 170px;
      }
    }

    &-note {
      padding: 20px 30px 30px;
      flex: 1;

      @include respond-to-max($mobilePortrait) {
        padding: 10px;
      }

      &-section {
        margin-bottom: 20px;
        font-weight: 700;
        color: #8D99AE;

        @include respond-to-max($mobilePortrait) {
          margin-bottom: 10px;
        }
      }

      &-name {
        margin-bottom: 20px;

        @include respond-to-max($mobilePortrait) {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__content {
    padding: 0 60px 60px;

    @include respond-to-max($mobileLandscape) {
      padding: 0 20px 30px;
    }

    &-footer {
      margin-top: 60px;

      @include respond-to-max($mobilePortrait) {
        margin-top: 40px;
      }

      button {
        width: 100%;
        max-width: 100%;
      }
    }

    .enter-input {
      display: flex;
      justify-content: space-between;

      @include respond-to-max($mobilePortrait) {
        margin-top: 20px;
        flex-direction: column;
        align-items: initial;
      }

      &__wrap {
        @include respond-to-max($mobilePortrait) {
          padding-top: 10px;
          padding-left: 0;
        }
      }

      &__btn {
        @include respond-to-max($mobilePortrait) {
          width: 40px;
          height: 40px;
        }
      }

      &__mask {
        @include respond-to-max($mobilePortrait) {
          width: 100%;
          flex: 1;
        }
      }
    }
  }
}
