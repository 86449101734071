@import "variables-graphics";
@import "variables-glyphicons";

[class*="icon-"],
[class*="bg-"] {
  background-position: center;
  background-repeat: no-repeat;
}

[class*="icon-"] {
  background-size: contain;
}

.icon-logo {
  display: block;
  width: 100%;
  padding-top: 100%;
  background-image: $icon-logo;
  background-color: $darkColor;
  background-size: auto 53%;
}

.icon-logo-mobile {
  display: block;
  width: 100%;
  padding-top: 100%;
  background-image: $icon-logo-mobile;
}

.icon-message {
  background-image: $icon-message;
}
.icon-phone {
  background-image: $icon-phone;
}
.icon-search {
  background-image: $icon-search;
}

.icon-check {
  background-image: $icon-check;
}

@font-face {
  font-family: 'hrc';
  src:  url('#{$icomoon-font-path}/hrc.eot?eexxgp');
  src:  url('#{$icomoon-font-path}/hrc.eot?eexxgp#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/hrc.ttf?eexxgp') format('truetype'),
  url('#{$icomoon-font-path}/hrc.woff?eexxgp') format('woff'),
  url('#{$icomoon-font-path}/hrc.svg?eexxgp#hrc') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-glyph-"], [class*=" icon-glyph-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hrc' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-glyph-arrow {
  &:before {
    content: $icon-glyph-arrow;
  }
}

.icon-glyph-curtains {
  font-size: 0.82em;
  &:before {
    content: $icon-glyph-curtains;
  }
}
.icon-glyph-linens {
  font-size: 0.81em;
  &:before {
    content: $icon-glyph-linens;
  }
}
.icon-glyph-napkin-stand {
  font-size: 0.97em;
  &:before {
    content: $icon-glyph-napkin-stand;
  }
}
.icon-glyph-restaurant-table {
  font-size: 0.79em;
  &:before {
    content: $icon-glyph-restaurant-table;
  }
}

.icon-glyph-armchair {
  font-size: 0.93em;
  &:before {
    content: $icon-glyph-armchair;
  }
}
.icon-glyph-bra {
  &:before {
    content: $icon-glyph-bra;
  }
}
.icon-glyph-chair {
  font-size: 0.93em;
  &:before {
    content: $icon-glyph-chair;
  }
}
.icon-glyph-cupboard {
  font-size: 1em;
  &:before {
    content: $icon-glyph-cupboard;
  }
}
.icon-glyph-lamp {
  &:before {
    content: $icon-glyph-lamp;
  }
}
.icon-glyph-napkins {
  &:before {
    content: $icon-glyph-napkins;
  }
}
.icon-glyph-nightstand {
  font-size: 0.77em;
  &:before {
    content: $icon-glyph-nightstand;
  }
}
.icon-glyph-plate {
  &:before {
    content: $icon-glyph-plate;
  }
}
.icon-glyph-sofa {
  font-size: 0.71em;
  &:before {
    content: $icon-glyph-sofa;
  }
}
.icon-glyph-spoons {
  &:before {
    content: $icon-glyph-spoons;
  }
}
.icon-glyph-table {
  font-size: 0.77em;
  &:before {
    content: $icon-glyph-table;
  }
}
.icon-glyph-towel {
  font-size: 0.77em;
  &:before {
    content: $icon-glyph-towel;
  }
}

.icon-cross {
  position: relative;
  display: inline-block;
  height: 2em;
  width: 2em;
  font-size: 15px;

  &:after,
  &:before {
    position: absolute;
    top: calc(50% - 0.5px);
    content: '';
    left: 0;
    height: 1px;
    width: 100%;
    font-size: 0.1em;
    background-color: #F0BC7D;
    transform-origin: 50% 50%;
    transition: background 0.2s;
  }

  &:before {
    transform: rotate(45deg) scale(1.3);
  }

  &:after {
    transform: rotate(-45deg) scale(1.3);
  }
}