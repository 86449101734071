@import "vars";

.wrapper,
.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding-left: $col-step / 2;
  padding-right: $col-step / 2; }

.container-fluid {
  width: 100%;
  padding-right: $col-step / 2;
  padding-left: $col-step / 2; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  width: auto;
  flex-flow: row wrap;
  margin-right: - $col-step / 2;
  margin-left: - $col-step / 2; }

[class*="col-"] {
  flex-basis: 100%; }

.to-column {
  flex-direction: column; }

.row-reverse {
  flex-direction: row-reverse; }

.col-reverse {
  flex-direction: column-reverse; }

.row-break {
  width: 100% !important; }

.col,
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $col-step / 2;
  padding-left: $col-step / 2; }

.col,
.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

@for $i from 1 through 12 {
  .col-xs-#{$i} {
    flex-basis: $column * $i;
    max-width: $column * $i; } }

@for $i from 1 through 12 {
  .xs-order-#{$i} {
    order: $i; } }

.col-xs-offset-0 {
  margin-left: 0; }

@for $i from 1 through 11 {
  .col-xs-offset-#{$i} {
    margin-left: $column * $i; } }

.start-xs {
  justify-content: flex-start; }

.center-xs {
  justify-content: center; }

.end-xs {
  justify-content: flex-end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.stretch-xs {
  align-items: stretch; }

.baseline-xs {
  align-items: baseline; }

.bottom-xs {
  align-items: flex-end; }

.between-xs {
  justify-content: space-between; }

.evenly-xs {
  justify-content: space-evenly; }

.around-xs {
  justify-content: space-around; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

.self-top {
  align-self: flex-start !important; }

.self-middle {
  align-self: center !important; }

.self-bottom {
  align-self: flex-end !important; }

@media only screen and (max-width: $tiny - 1) {
  @for $i from 1 through 12 {
    .xs-order-#{$i} {
      order: $i; } } }

@media only screen and (min-width: $tiny) {
  .wrapper {
    width: calc(#{$tiny} - 6.25%); }

  .container {
    width: 540px; }

  .col-tn,
  .col-tn-1,
  .col-tn-2,
  .col-tn-3,
  .col-tn-4,
  .col-tn-5,
  .col-tn-6,
  .col-tn-7,
  .col-tn-8,
  .col-tn-9,
  .col-tn-10,
  .col-tn-11,
  .col-tn-12,
  .col-tn-offset-0,
  .col-tn-offset-1,
  .col-tn-offset-2,
  .col-tn-offset-3,
  .col-tn-offset-4,
  .col-tn-offset-5,
  .col-tn-offset-6,
  .col-tn-offset-7,
  .col-tn-offset-8,
  .col-tn-offset-9,
  .col-tn-offset-10,
  .col-tn-offset-11,
  .col-tn-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $col-step / 2;
    padding-left: $col-step / 2; }

  .col-tn {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }

  @for $i from 1 through 12 {
    .col-tn-#{$i} {
      flex-basis: $column * $i;
      max-width: $column * $i; } }

  .col-tn-offset-0 {
    margin-left: 0; }

  @for $i from 1 through 11 {
    .col-tn-offset-#{$i} {
      margin-left: $column * $i; } }

  .start-tn {
    justify-content: flext-start; }

  .center-tn {
    justify-content: center; }

  .end-tn {
    justify-content: flex-end; }

  .stretch-tn {
    align-items: stretch; }

  .baseline-tn {
    align-items: baseline; }

  .top-tn {
    align-items: flex-start; }

  .middle-tn {
    align-items: center; }

  .bottom-tn {
    align-items: flex-end; }

  .between-tn {
    justify-content: space-between; }

  .around-tn {
    justify-content: space-around; }

  .evenly-tn {
    justify-content: space-evenly; }

  .first-tn {
    order: -1; }

  .last-tn {
    order: 1; } }

@media only screen and (max-width: $small - 1) {
  @for $i from 1 through 12 {
    .tn-order-#{$i} {
      order: $i; } } }

@media only screen and (min-width: $small) {
  .wrapper {
    width: calc(#{$small} - 6.25%); }

  .container {
    width: 720px; }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $col-step / 2;
    padding-left: $col-step / 2; }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }

  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      flex-basis: $column * $i;
      max-width: $column * $i; } }

  .col-sm-offset-0 {
    margin-left: 0; }

  @for $i from 1 through 11 {
    .col-sm-offset-#{$i} {
      margin-left: $column * $i; } }

  .start-sm {
    justify-content: flext-start; }

  .center-sm {
    justify-content: center; }

  .end-sm {
    justify-content: flex-end; }

  .stretch-sm {
    align-items: stretch; }

  .baseline-sm {
    align-items: baseline; }

  .top-sm {
    align-items: flex-start; }

  .middle-sm {
    align-items: center; }

  .bottom-sm {
    align-items: flex-end; }

  .between-sm {
    justify-content: space-between; }

  .around-sm {
    justify-content: space-around; }

  .evenly-sm {
    justify-content: space-evenly; }

  .first-sm {
    order: -1; }

  .last-sm {
    order: 1; } }

@media only screen and (max-width: $medium - 1) {
  @for $i from 1 through 12 {
    .sm-order-#{$i} {
      order: $i; } } }

@media only screen and (min-width: $medium) {
  .wrapper {
    width: calc(#{$medium} - 6.25%); }

  .container {
    width: 930px; }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $col-step / 2;
    padding-left: $col-step / 2; }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      flex-basis: $column * $i;
      max-width: $column * $i; } }

  .col-md-offset-0 {
    margin-left: 0; }

  @for $i from 1 through 11 {
    .col-md-offset-#{$i} {
      margin-left: $column * $i; } }

  .start-md {
    justify-content: flext-start; }

  .center-md {
    justify-content: center; }

  .end-md {
    justify-content: flex-end; }

  .stretch-md {
    align-items: stretch; }

  .baseline-md {
    align-items: baseline; }

  .top-md {
    align-items: flex-start; }

  .middle-md {
    align-items: center; }

  .bottom-md {
    align-items: flex-end; }

  .between-md {
    justify-content: space-between; }

  .around-md {
    justify-content: space-around; }

  .evenly-md {
    justify-content: space-evenly; }

  .first-md {
    order: -1; }

  .last-md {
    order: 1; } }

@media only screen and (max-width: $large - 1) {
  @for $i from 1 through 12 {
    .md-order-#{$i} {
      order: $i; } } }

@media only screen and (min-width: $large) {
  .wrapper {
    width: calc(#{$large} - 6.25%); }

  .container {
    width: $container; }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $col-step / 2;
    padding-left: $col-step / 2; }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }

  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      flex-basis: $column * $i;
      max-width: $column * $i; } }

  .col-lg-offset-0 {
    margin-left: 0; }

  @for $i from 1 through 11 {
    .col-lg-offset-#{$i} {
      margin-left: $column * $i; } }

  .start-lg {
    justify-content: flext-start; }

  .center-lg {
    justify-content: center; }

  .end-lg {
    justify-content: flex-end; }

  .stretch-lg {
    align-items: stretch; }

  .baseline-lg {
    align-items: baseline; }

  .top-lg {
    align-items: flex-start; }

  .middle-lg {
    align-items: center; }

  .bottom-lg {
    align-items: flex-end; }

  .between-lg {
    justify-content: space-between; }

  .around-lg {
    justify-content: space-around; }

  .evenly-lg {
    justify-content: space-evenly; }

  .first-lg {
    order: -1; }

  .last-lg {
    order: 1; } }

@media only screen and (max-width: $extraLarge - 1) {
  @for $i from 1 through 12 {
    .lg-order-#{$i} {
      order: $i; } } }

@media only screen and (min-width: $extraLarge) {
  .wrapper {
    width: calc(#{$extraLarge} - 6.25%); }

  .container {
    width: $container; }

  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-offset-0,
  .col-xl-offset-1,
  .col-xl-offset-2,
  .col-xl-offset-3,
  .col-xl-offset-4,
  .col-xl-offset-5,
  .col-xl-offset-6,
  .col-xl-offset-7,
  .col-xl-offset-8,
  .col-xl-offset-9,
  .col-xl-offset-10,
  .col-xl-offset-11,
  .col-xl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $col-step / 2;
    padding-left: $col-step / 2; }

  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }

  @for $i from 1 through 12 {
    .col-xl-#{$i} {
      flex-basis: $column * $i;
      max-width: $column * $i; } }

  .col-xl-offset-0 {
    margin-left: 0; }

  @for $i from 1 through 11 {
    .col-xl-offset-#{$i} {
      margin-left: $column * $i; } }

  .start-xl {
    justify-content: flext-start; }

  .center-xl {
    justify-content: center; }

  .end-xl {
    justify-content: flex-end; }

  .stretch-xl {
    align-items: stretch; }

  .baseline-xl {
    align-items: baseline; }

  .top-xl {
    align-items: flex-start; }

  .middle-xl {
    align-items: center; }

  .bottom-xl {
    align-items: flex-end; }

  .between-xl {
    justify-content: space-between; }

  .around-xl {
    justify-content: space-around; }

  .evenly-xl {
    justify-content: space-evenly; }

  .first-xl {
    order: -1; }

  .last-xl {
    order: 1; } }

@media only screen and (min-width: $extraLarge) {
  @for $i from 1 through 12 {
    .lg-order-#{$i} {
      order: $i; } } }
