@import "../mixins/mixins";
@import "../commons/variables";

.acc-button-element {

  i {
    display: none;
    position: absolute;
    width: 40px;
    top: 50%;
    right: 0;
    font-size: 12px;
    pointer-events: none;
    transform: translate3d(0, -50%, 0);
    background-size: 10px auto;

    &:before {
      display: inline-block;
      transform: rotate(90deg);
      transition: transform 0.2s;
    }
  }
}

.start-acc {
  .acc-button-element {
    position: relative;
    padding-right: 40px;
    padding-left: 40px;
    cursor: pointer;

    i {
      display: inline-block;
    }
  }
}

.acc-active {
  i {
    &:before {
      transform: rotate(-90deg);
    }
  }
}