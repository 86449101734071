@import "../mixins/mixins";
@import "../commons/variables";

.hrc-tag {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 22px;
  text-transform: uppercase;

  @include respond-to-max($large) {
    font-size: 20px;
  }

  @include respond-to-max($tabletLandscape) {
    font-size: 18px;
  }

  @include respond-to-max($tabletPortrait) {
    font-size: 16px;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 14px;
  }
}

.tag_hotel {
  color: #F8DDD2;
  background-color: #76351B;
}

.tag_restaurant {
  color: #D2F8F6;
  background-color: #1B6476;
}

.tag_hospital {
  color: #FCDCDC;
  background-color: #761B1B;
}

.tag_cafe {
  color: #DAD2F8;
  background-color: #3C1B76;
}

.tag_airport {
  color: #DAFAEC;
  background-color: #1B764E;
}

.tag_other{
  color: #FDE0FF;
  background-color: #6F1B76;
}