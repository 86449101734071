html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
    vertical-align: baseline;
    background: transparent;
}

article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
    display: block;
}
html {
    box-sizing: border-box;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: 100%;
    color: #000;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;

}
img,
object,
embed {
    max-width: 100%;
}

fieldset,
img {
    border: 0;
}
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    outline: none;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted #000;
    cursor: help;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
th {
    font-weight: bold;
    vertical-align: bottom;
}
td {
    font-weight: normal;
    vertical-align: top;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

input,
select,
textarea {
    vertical-align: middle;
    -webkit-appearance: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    outline: none;
}

pre {
    white-space: pre-line;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input::-webkit-clear-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
input::-webkit-search-cancel-button {
    display: none;
}
input::-ms-clear {
    display: none;
}
figure {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
}
[type=reset],
[type=submit] {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

input::-webkit-date-and-time-value {
    margin: 0;
}

input[type='radio'] {
    vertical-align: text-bottom;
}
input[type='checkbox'] {
    vertical-align: bottom;
}

small {
    font-size: 85%;
}

strong {
    font-weight: bold;
}

td,
td,
img {
    vertical-align: top;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

pre,
code,
kbd,
samp {
    font-family: monospace, sans-serif;
}

label,
input[type=button],
input[type=submit],
input[type=file],
button {
    font-family: inherit;
    cursor: pointer;
    outline: none;
}

a,
button::-moz-focus-inner {
    border: 0;
}

button,
input,
select,
textarea {
    margin: 0;
    padding: 0;
    outline: none;
}

button,
input[type=button] {
    width: auto;
    overflow: visible;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 0;
    padding: 0;
}

/* Reset autocomplit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
}
