.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position:relative;
  overflow:hidden;
  /* Fix of Webkit flickering */
  z-index:1;
}
.swiper-container-no-flexbox {
  .swiper-slide {
    float: left;
  }
}
.swiper-container-vertical > .swiper-wrapper{
  flex-direction: column;
}
.swiper-wrapper {
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property:transform;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide, .swiper-wrapper {
  transform:translate3d(0, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  position: relative;
}
/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  touch-action: pan-y;
}
.swiper-wp8-vertical {
  touch-action: pan-x;
}