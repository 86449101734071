@import "../plugs/reset";
@import "ui-elements";
@import "../plugs/equalize/equalize";
@import "../commons/bounding-wrapper";
@import "../partials/header";
@import "../partials/social";
@import "../partials/footer";
@import "../plugs/accordion";

body {
  &.no-scroll {
    overflow: hidden;
  }
}

.wrap {
  min-width: 320px;
}

.hrc-center-in {
  text-align: center;
}