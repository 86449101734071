@import "../../mixins/mixins";
@import "../../commons/variables";

.b-services {
  position: relative;
  padding: 80px 20px;

  @include respond-to-max($mobileLandscape) {
    padding: 30px 20px;
  }

  &__top {
    position: relative;
    margin-bottom: 540px;
    z-index: 1;

    @include respond-to-max($small) {
      margin-bottom: 270px;
    }
  }

  &__lead {
    margin-bottom: 1.42em;
    text-align: center;
    color: #E4DFA8;

    @include respond-to-max($small) {
      margin-bottom: 30px;
    }
  }

  &__heading {
    margin: 0 auto 1.16em;
    max-width: 1100px;
    text-align: center;
    color: #FBFBF2;
  }

  &__switches {
    display: flex;

    @include respond-to-max($small) {
      flex-direction: column;
    }

    li {
      flex: 1;
      text-align: center;

      @include respond-to-max($small) {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__flow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__link {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    transition: opacity 0.4s;

    &:after {
      @include after-or-before-over-block {
        background: linear-gradient(180deg, #1B2037 0%, rgba(66, 68, 75, 0.4) 95%);
        pointer-events: none;
      }
    }

    &-block {
      position: relative;
      text-align: center;
      z-index: 1;

      .hrc-lead {
        max-width: 900px;
        padding: 70px 40px;
        font-weight: 500;
        color: #ffffff;

        @include respond-to-max($mobilePortrait) {
          padding: 70px 20px;
        }
      }
    }
  }

  &__button {
    width: 320px;
    margin-bottom: 80px;
    color: #FFFFFF;
    background-color: $redActiveColor;

    @include respond-to-max($mobileLandscape) {
      max-width: 320px;
      width: 100%;
      margin-bottom: 30px;
    }


    &:hover {
      background-color: lighten($redActiveColor, 5%);
    }
  }
}

.services {
  &__item {
    display: block;
    opacity: 0.5;
    transition: opacity 0.4s;

    @include respond-to-max($tabletPortrait) {
      display: inline-block;
    }

    &:hover {
      opacity: 1;

      .services__icon-group {
        opacity: 1;
      }
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }
  &__icon-group {
    position: relative;
    display: block;
    height: 140px;
    width: 140px;
    margin: 0 auto 20px;
    opacity: 0.5;
    transition: opacity 0.4s;

    @include respond-to-max($small) {
      display: none;
    }
  }
  &__icon_front {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: 1;
  }
  &__icon_anim {
    position: absolute;
    display: block;
    width: 100%;
    z-index: 0;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

[for="service-interior"] {
  .services {
    &__icon_front {
      background-size: auto 78%;
      background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='119' height='103' viewBox='0 0 119 103' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cg transform='translate(5087 -4476)'%3E%3Cmask id='b'%3E%3Cuse xlink:href='%23a' fill='%23fff' transform='translate(-5096 4476)'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cuse xlink:href='%23c' transform='translate(-5096 4476)' fill='%23FFF'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cpath id='a' d='M68.468 0l59.294 102.75H9.172L68.469 0z'/%3E%3Cpath id='c' d='M68.468 0L70.2-1 68.467-4 66.736-1l1.733 1zm59.294 102.75v2h3.463l-1.731-3-1.732 1zm-118.59 0l-1.731-1-1.731 3h3.463v-2zM66.736 1l59.295 102.75 3.464-2L70.2-1l-3.465 2zm61.027 99.75H9.172v4h118.59v-4zm-116.857 3L70.2 1l-3.465-2L7.441 101.75l3.464 2z'/%3E%3C/defs%3E%3C/svg%3E");
      background-position: left top;
    }
    &__icon_anim {
      height: 50%;
      left: 40px;
      top: 20px;
    }
  }
}
[for="service-design"] {
  .services {
    &__icon_front {
      background-size: auto 89%;
      background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='126' height='125' viewBox='0 0 126 125' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cg transform='translate(4715 -4461)'%3E%3Cmask id='b'%3E%3Cuse xlink:href='%23a' fill='%23fff' transform='translate(-4714.33 4461)'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cuse xlink:href='%23c' transform='translate(-4714.33 4461)' fill='%23FFF'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cpath id='a' d='M62.667 0l62.666 62.5L62.667 125 0 62.5 62.667 0z'/%3E%3Cpath id='c' d='M62.667 0l1.412-1.416-1.412-1.409-1.413 1.409L62.667 0zm62.666 62.5l1.413 1.416 1.42-1.416-1.42-1.416-1.413 1.416zM62.667 125l-1.413 1.416 1.413 1.409 1.412-1.409L62.667 125zM0 62.5l-1.412-1.416-1.42 1.416 1.42 1.416L0 62.5zM61.254 1.416l62.667 62.5 2.825-2.832-62.667-62.5-2.825 2.832zm62.667 59.668l-62.667 62.5 2.825 2.832 62.667-62.5-2.825-2.832zm-59.842 62.5l-62.667-62.5-2.824 2.832 62.666 62.5 2.825-2.832zM1.412 63.916l62.667-62.5-2.825-2.832-62.666 62.5 2.824 2.832z'/%3E%3C/defs%3E%3C/svg%3E");
      background-position: right top;
    }
    &__icon_anim {
      height: 55%;
      right: 40px;
      top: 26px;
    }
  }
}
[for="service-architecture"] {
  .services {
    &__icon_front {
      background-size: auto 82%;
      background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='116' height='113' viewBox='0 0 116 113' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cg transform='translate(4346 -4465)'%3E%3Cmask id='b'%3E%3Cuse xlink:href='%23a' fill='%23fff' transform='translate(-4347.12 4465)'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cuse xlink:href='%23c' transform='translate(-4347.12 4465)' fill='%23FFF'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cpath id='a' d='M59.058 0l46.173 22.214 11.403 49.915-31.952 40.028H33.434L1.48 72.129l11.403-49.915L59.058 0z'/%3E%3Cpath id='c' d='M59.058 0l.867-1.802-.867-.417-.867.417L59.057 0zm46.173 22.214l1.949-.445-.214-.94-.868-.417-.867 1.802zm11.403 49.915l1.564 1.247.601-.753-.215-.94-1.95.446zm-31.952 40.028v2h.962l.6-.752-1.562-1.248zm-51.248 0l-1.564 1.248.6.752h.964v-2zM1.48 72.129l-1.95-.446-.215.94.602.753L1.48 72.13zm11.403-49.915l-.867-1.802-.868.418-.214.939 1.95.445zM58.19 1.802l46.174 22.214 1.734-3.604L59.925-1.802 58.19 1.802zm45.091 20.858l11.404 49.914 3.899-.89-11.404-49.915-3.899.89zm11.79 48.221l-31.952 40.028 3.126 2.496 31.953-40.029-3.127-2.495zm-30.39 39.276H33.435v4h51.248v-4zm-49.684.752L3.043 70.881l-3.126 2.495 31.952 40.029 3.126-2.496zM3.43 72.574L14.834 22.66l-3.9-.891L-.468 71.683l3.9.891zm10.322-48.558L59.925 1.802 58.19-1.802 12.017 20.412l1.735 3.604z'/%3E%3C/defs%3E%3C/svg%3E");
      background-position: right top;
    }
    &__icon_anim {
      height: 57%;
      right: 35px;
      top: 15px;
    }
  }
}
[for="service-installation"] {
  .services {
    &__icon_front {
      background-size: auto 75%;
      background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='107' height='107' viewBox='0 0 107 107' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cg transform='translate(3993 -4470)'%3E%3Cmask id='b'%3E%3Cuse xlink:href='%23a' fill='%23fff' transform='matrix(0.707107 0.707107 -0.707107 0.707107 -3939.5 4417)'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cuse xlink:href='%23c' transform='matrix(0.707107 0.707107 -0.707107 0.707107 -3939.5 4417)' fill='%23FFF'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cpath id='a' d='M75.307 0l75.307 75.307-75.307 75.307L0 75.307 75.307 0z'/%3E%3Cpath id='c' d='M75.307 0l1.414-1.414-1.414-1.414-1.414 1.414L75.307 0zm75.307 75.307l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414zm-75.307 75.307l-1.414 1.414 1.414 1.414 1.414-1.414-1.414-1.414zM0 75.307l-1.414-1.414-1.414 1.414 1.414 1.414L0 75.307zM73.893 1.414L149.2 76.721l2.828-2.828L76.721-1.414l-2.828 2.828zM149.2 73.893L73.893 149.2l2.828 2.828 75.307-75.307-2.828-2.828zM76.721 149.2L1.414 73.893l-2.828 2.828 75.307 75.307 2.828-2.828zM1.414 76.721L76.721 1.414l-2.828-2.828-75.307 75.307 2.828 2.828z'/%3E%3C/defs%3E%3C/svg%3E");
      background-position: center top;
    }
    &__icon_anim {
      height: 64%;
      right: 0;
      top: 5%;
    }
  }
}
[for="service-delivery"] {
  .services {
    &__icon_front {
      background-size: auto 79%;
      background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='111' height='111' viewBox='0 0 111 111' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cg transform='translate(3638 -4467)'%3E%3Cmask id='b'%3E%3Cuse xlink:href='%23a' fill='%23fff' transform='translate(-3638 4467)'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cuse xlink:href='%23c' transform='translate(-3638 4467)' fill='%23FFF'/%3E%3C/g%3E%3C/g%3E%3Cuse xlink:href='%23d' transform='translate(3638 -4467) matrix(0.830566 0.55692 -0.55692 0.830566 -3568.09 4492)' fill='%23FFF'/%3E%3Cdefs%3E%3Cpath id='a' d='M111 55.5c0 30.652-24.848 55.5-55.5 55.5S0 86.152 0 55.5 24.848 0 55.5 0 111 24.848 111 55.5z'/%3E%3Cpath id='c' d='M109 55.5c0 29.547-23.953 53.5-53.5 53.5v4c31.756 0 57.5-25.744 57.5-57.5h-4zM55.5 109C25.953 109 2 85.047 2 55.5h-4C-2 87.256 23.744 113 55.5 113v-4zM2 55.5C2 25.953 25.953 2 55.5 2v-4C23.744-2-2 23.744-2 55.5h4zM55.5 2C85.047 2 109 25.953 109 55.5h4C113 23.744 87.256-2 55.5-2v4z'/%3E%3Cpath id='d' d='M.406-.914a1 1 0 0 0-1.32.508l-3.654 8.225a1 1 0 0 0 1.827.812L.508 1.32l7.31 3.248a1 1 0 0 0 .813-1.827L.406-.914zm26.717 68.631L.933-.359-.933.359l26.19 68.077 1.866-.719z'/%3E%3C/defs%3E%3C/svg%3E");
      background-position: right top;
    }
    &__icon_anim {
      transform: rotate(42.87deg);
      height: 50%;
      right: 10px;
      top: 10px;
    }
  }
}

#service-interior:checked {
  & ~ .b-services__top [for="service-interior"] {
    opacity: 1;

    .services__icon-group {
      opacity: 1;
    }

    .cls-interior {
      animation:dash 80s linear infinite;
    }
  }
  & ~ .b-services__flow [data-service-link="interior"] {
    pointer-events: auto;
    opacity: 1;
  }
}

#service-design:checked {
  & ~ .b-services__top [for="service-design"] {
    opacity: 1;

    .services__icon-group {
      opacity: 1;
    }

    .cls-design{
      animation:dash 80s linear infinite;
    }
  }
  & ~ .b-services__flow [data-service-link="design"] {
    pointer-events: auto;
    opacity: 1;
  }
}

#service-architecture:checked {
  & ~ .b-services__top [for="service-architecture"] {
    opacity: 1;

    .services__icon-group {
      opacity: 1;
    }

    .cls-architecture-1,
    .cls-architecture-2 {
      animation:dash 80s linear reverse infinite;
    }
  }
  & ~ .b-services__flow [data-service-link="architecture"] {
    pointer-events: auto;
    opacity: 1;
  }
}

#service-installation:checked {
  & ~ .b-services__top [for="service-installation"] {
    opacity: 1;

    .services__icon-group {
      opacity: 1;
    }

    .cls-installation {
      animation:dash 80s linear reverse infinite;
    }

    .cls-installation:last-child {
      animation:dash 80s linear infinite;
    }
  }
  & ~ .b-services__flow [data-service-link="installation"] {
    pointer-events: auto;
    opacity: 1;
  }
}

#service-delivery:checked {
  & ~ .b-services__top [for="service-delivery"] {
    opacity: 1;

    .services__icon-group {
      opacity: 1;
    }

    .cls-delivery{
      animation:dash 80s linear infinite;
    }
  }
  & ~ .b-services__flow [data-service-link="delivery"] {
    pointer-events: auto;
    opacity: 1;
  }
}

@keyframes dash {
  to {stroke-dashoffset: 1000;}
}