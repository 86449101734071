@import "../mixins/mixins";
@import "../commons/variables";

.hidden-sublist {
  flex: 0 0 100%;
  height: 0;
  display: none;
  overflow: hidden;

  li  {
    padding-left: 20px;
    margin-bottom: 20px;

    a {
      font-size: 16px;
      font-weight: 300;
      color: $darkColor;
    }
  }
}

.action__toggle-sublist {
  position: relative;

  &:after,
  &:before {
    @include after-or-before-over-block {
      top: 50%;
      left: 50%;
      background-color: $darkColor;
      transform: translate(-50%, -50%) scale(1, 1);
    }
  }

  &:after {
    height: 22px;
    width: 2px;
    transition: transform 0.2s;
  }

  &:before {
    height: 2px;
    width: 22px;
  }

  &.hidden-block__shown {
    &:after {
      transform: translate(-50%, -50%) scale(1, 0);
    }
  }
}