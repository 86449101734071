@import "variables";
@import "../mixins/mixins";
@import "../commons/variables-graphics";

button {
  transition: opacity 0.2s,
              color 0.2s,
              background 0.2s,
              border 0.2s;
}

.hrc-btn {
  display: inline-block;
  height: 3.3em;
  padding: 0 15px;
  min-width: 320px;
  text-align: center;
  font-size: 24px;
  line-height: calc(3.3em - 4px);
  font-weight: 700;
  transition: opacity 0.4s,
              color 0.4s,
              background 0.4s,
              border 0.4s;

  @include respond-to-max($tabletLandscape) {
    height: 3em;
    font-size: 20px;
    line-height: calc(3em - 4px);
    width: 100%;
    max-width: 300px;
    min-width: 0;
  }

  @include respond-to-max($mobileLandscape) {
    height: 2.7em;
    font-size: 18px;
    line-height: calc(2.7em - 4px);
    width: 100%;
    max-width: 300px;
    min-width: 0;
  }

  &_transparent {
    @extend .hrc-btn;
    background-color: transparent;
  }

  &_fill {
    @extend .hrc-btn;
    line-height: 3.3em;

    @include respond-to-max($tabletLandscape) {
      line-height: 3em;
    }

    @include respond-to-max($mobileLandscape) {
      line-height: 2.7em;
    }

    &.hrc-btn_orange {
      color: #FFFFFF;
      background-color: #BE7355;
      border: 2px solid #BE7355;

      &:hover {
        background-color: lighten(#BE7355, 5%);
      }
    }

    &.hrc-btn_red {
      color: #FFFFFF;
      background-color: $redActiveColor;
      border: 2px solid $redActiveColor;

      &:hover {
        background-color: lighten($redActiveColor, 5%);
      }
    }
  }

  &_white {
    color: #ffffff;
    border: 2px solid #ffffff;

    &.hrc-btn_transparent {
      &:hover {
        color: $darkColor;
        background-color: #FFFFFF;
      }
    }
  }
  &_orange {
    color: #BE7355;
    border: 2px solid #BE7355;

    &.hrc-btn_transparent {
      &:hover {
        color: #ffffff;
        background-color: #BE7355;
      }
    }
  }
  &_orange-soft {
    color: #E1997C;
    border: 2px solid #E1997C;

    &.hrc-btn_transparent {
      &:hover {
        color: #ffffff;
        background-color: #E1997C;
      }
    }
  }
  &_grayish {
    color: $grayish;
    border: 2px solid $grayish;

    &.hrc-btn_transparent {
      &:hover {
        color: #ffffff;
        background-color: $grayish;
      }
    }
  }
  &_red {
    color: $redActiveColor;
    border: 2px solid $redActiveColor;

    &.hrc-btn_transparent {
      &:hover {
        color: #ffffff;
        background-color: $redActiveColor;
      }
    }
  }
  &_brown {
    color: #76351b;
    border: 2px solid #76351b;

    &.hrc-btn_transparent {
      &:hover {
        color: #ffffff;
        background-color: #76351b;
      }
    }
  }
}

.btn-arrow-anim {
  position: relative;
  padding: 0 20px;
  transition-delay: 0.2s;

  &:after,
  &:before {
    @include after-or-before-over-block;
    left: auto;
    right: 20px;
    width: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.2s,
                transform 0.4s;
  }

  &:after {
    animation: animArrowShow 0.4s;
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='19' height='16' viewBox='0 0 19 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cuse xlink:href='%23a' transform='translate(4732 -3742) matrix(1 -2.44929e-16 2.44929e-16 1 -4732 3750)' fill='%23FFF'/%3E%3Cdefs%3E%3Cpath id='a' d='M18.707.707a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 1 0-1.414 1.414L16.586 0l-5.657 5.657a1 1 0 0 0 1.414 1.414L18.707.707zM0 1h18v-2H0v2z'/%3E%3C/defs%3E%3C/svg%3E");
  }
  &:before {
    animation: animArrowHide 0.2s;
    opacity: 0;
    transform: translate(0, 0);
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='19' height='16' viewBox='0 0 19 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cuse xlink:href='%23a' transform='translate(4732 -3742) matrix(1 -2.44929e-16 2.44929e-16 1 -4732 3750)' fill='%232D2F38'/%3E%3Cdefs%3E%3Cpath id='a' d='M18.707.707a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 1 0-1.414 1.414L16.586 0l-5.657 5.657a1 1 0 0 0 1.414 1.414L18.707.707zM0 1h18v-2H0v2z'/%3E%3C/defs%3E%3C/svg%3E");
  }

  &:hover {
    &:after {
      animation: animArrowHide 0.2s;
      opacity: 0;
      transform: (-20px, 0);
    }
    &:before {
      animation: animArrowShow 0.4s;
      opacity: 1;
      transform: (0, 0);
    }
  }
}

.hrc-btn__social {
  display: block;
  height: 2em;
  width: 2em;
  font-size: 30px;
  background-position: center;
  background-repeat: no-repeat;

  @include respond-to-max($mobileLandscape) {
    font-size: 24px;
  }
}

.social_facebook {
  background-color: #517CBE;
  background-image: $icon-facebook;

  &:hover {
    background-color: lighten(#517CBE, 5%);
  }
}

.social_instagram {
  background-color: #C22F86;
  background-image: $icon-instagram;

  &:hover {
    background-color: lighten(#C22F86, 5%);
  }
}

.social_pinterest {
  background-color: #F1605C;
  background-image: $icon-pinterest;

  &:hover {
    background-color: lighten(#F1605C, 5%);
  }
}

@keyframes animArrowShow {
  0% { opacity: 0; transform: translate(-30px, 0); }
  100% { opacity: 1; transform: translate(0, 0); }
}

@keyframes animArrowHide {
  0% { opacity: 1; transform: translate(0, 0); }
  99% { opacity: 0; transform: translate(20px, 0); }
  100% { opacity: 0; transform: translate(-30px, 0); }
}
