@import "mixins/mixins";
@import "commons/variables";

@import "commons/typography";
@import "commons/graphics";
@import "partials/cutting-icons";
@import "commons/common";

@import "plugs/swiper";
@import "plugs/slider-controls";

@import "partials/reference";
@import "partials/main/services";
@import "partials/main/production";
@import "partials/tags";
@import "partials/popup";

.l-header .b-header {
  background-color: rgba(45, 47, 56, 0.75);

  &__nav-link {
    color: #B6BBC3;

    &:hover {
      color: lighten(#B6BBC3, 20%);
    }
  }
}

.header-state__mobile {
  &.b-header {
    .b-header {
      &__hide-block {
        background-color: rgba(45, 47, 56, 0.75);
      }
      &__burger {
        background-color: $redActiveColor;
      }
    }
  }
}

.l-main {
  padding-top: 0 !important;
}

.b-hero {
  position: relative;
  display: flex;
  height: 100vh;
  overflow: hidden;

  &__logo-mobile {
    display: none;
    position: absolute;
    left: calc(50% - 45px);
    height: 90px;
    width: 90px;
    padding-top: 0;
    z-index: 2;

    @include respond-to-max($menuMobilePoint) {
      display: block;
      top: 130px;
    }

    @include respond-to-max($mobileLandscape) {
      top: 100px;
      left: calc(50% - 30px);
      height: 64px;
      width: 64px;
    }
  }

  &__item {
    position: relative;
    padding: $heightHeader 0;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include height-to-max(450px) {
      padding: 90px 0 60px;
    }

    @include respond-to-max($mobileLandscape) {
      padding: 90px 0 80px;
    }

    &:after {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      width: 100%;
      pointer-events: none;
      background: rgba(7, 8, 28, 0.4);
    }

    &-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  &__heading {
    max-width: 800px;
    margin: auto;
    text-align: center;
    color: #FFFFFF;

    @include respond-to-max(920px) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @include respond-to-max($mobileLandscape) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
  .swiper-pagination {
    bottom: 5.5vh;
  }

  .swiper-button {
    @include respond-to-max($mobileLandscape) {
      display: none;
    }
  }
}

.b-catalog {
  position: relative;
  display: flex;
  padding: 80px 0;
  background-color: $darkColor;

  @include respond-to-max($tabletPortrait) {
    flex-direction: column;
    padding: 20px 0;
  }

  &:after {
    @include after-or-before-over-block;
    pointer-events: none;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
    transition: opacity 0.4s;
  }

  &.element-under-hover {
    &:after {
      opacity: 1;
    }
  }

  .side_left {
    direction: rtl;

    @include respond-to-max($tabletPortrait) {
      direction: ltr;
    }
  }

  .cut-icon {
    align-items: center;
    padding: 0 20px;
    font-size: 60px;
    color: #5E6272;

    @include respond-to-max($tabletPortrait) {
      font-size: 40px;
    }

    i {
      font-size: 1em !important;
    }
  }

  &__button {
    position: relative;
    flex: 0 0 auto;
    margin: 0 10px;
    z-index: 1;

    &.m-catalog__button_mobile {
      display: none;
    }

    @include respond-to-max($menuMobilePoint) {
      &.m-catalog__button_mobile {
        display: block;
      }

      &.m-catalog__button_desktop {
        display: none;
      }
    }

    @include respond-to-max($tabletPortrait) {
      margin: 20px auto;
    }
  }
}

.b-references {
  &,
  .row {
    height: 960px;

    @include respond-to-max($large) {
      height: auto;
    }
  }

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background-color: #42444B;

    .heading-h2 {
      margin-bottom: 20px;
      text-transform: capitalize;
    }

    @include respond-to-max($small - 1px) {
      padding: 40px 20px;
    }

    & * {
      text-align: center;
      color: #ffffff;
    }

    &-button {
      display: block;

      @include respond-to-max(1200px) {
        font-size: 18px;
      }

      &_desktop {
        margin: 100px auto 0;
        width: 400px;

        @include respond-to-max(1360px) {
          width: 100%;
        }

        @include respond-to-max(1320px) {
          &:after,
          &:before {
            display: none;
          }
        }

        @include respond-to-max($small - 1px) {
          display: none;
        }
      }
    }
  }

  &__footer {
    display: none;
    flex: 0 0 100%;
    padding: 30px 20px;
    background-color: #42444B;

    .b-references__heading-button {
      margin: 0 auto;
    }

    @include respond-to-max($small - 1px) {
      display: block;
    }
  }
}

.reference {
  &__heading {
    &-button_desktop {
      margin: 100px auto 0;
      width: 400px;

      @include respond-to-max($small) {
        display: none;
      }
    }
  }

  &__poster {

    @include respond-to-max($large) {
      min-height: 40vw;
    }

    @include respond-to-max($mobileLandscape) {
      height: 300px;
    }
  }
}

.b-seo {
  display: flex;
  flex-wrap: wrap;

  &__poster {
    flex: 0 0 29%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include respond-to-max(1300px) {
      height: 350px;
      flex: 0 0 100%;
    }

    @include respond-to-max($tabletLandscape) {
      height: 250px;
    }

    @include respond-to-max($mobileLandscape) {
      height: 180px;
    }
  }
  &__content {
    padding: 120px 60px;
    flex: 1;
    background-color: $darkColor;

    @include respond-to-max($small) {
      padding: 60px 20px;
    }

    @include respond-to-max($mobileLandscape) {
      padding: 30px 20px;
    }
  }

  &__heading {
    margin-bottom: 1.58em;
    color: #BE7355;

    @include respond-to-max($tabletPortrait) {
      text-align: center;
    }
  }

  &__note {
    display: flex;

    @include respond-to-max($tabletPortrait) {
      flex-direction: column;
    }

    .hrc-regular + .hrc-regular {
        margin-top: 0.5em;
    }

    & * {
      color: #ABABAB;

      @include respond-to-max($tabletPortrait) {
        text-align: center;
      }
    }
  }
  &__column {
    flex: 0 0 50%;

    @include respond-to-max($tabletPortrait) {
      flex: 1 0 auto;
    }

    &:first-child {
      .hrc-lead {
        color: #929292;
      }

      @include respond-to-max($tabletPortrait) {
        margin-bottom: 30px;
      }
    }

    &:nth-child(odd) {
      padding-right: 50px;

      @include respond-to-max($tabletPortrait) {
        padding-right: 0;
      }
    }
    &:nth-child(even) {
      padding-left: 50px;

      @include respond-to-max($tabletPortrait) {
        padding-left: 0;
      }
    }
  }
}

