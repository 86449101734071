@import "variables";
@import "../mixins/mixins";
@import "../commons/buttons";
@import "../plugs/animInputs";

* {
  //@include scroll-bar(8px, #2D2D2D, $primeColor);
}

input {
  width: 200px;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;

  @include placeholder;
}

