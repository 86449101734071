@import "variables";
@import "../mixins/mixins";

html {
  font-family: 'Roboto', sans-serif;
}

.text {
  font-family: 'Roboto', sans-serif;
  line-height: 1.177em;
  color: $darkColor;
}

.heading-biggest {
  @extend .text;
  font-size: 240px;
  font-weight: 100;
}

.heading-h1 {
  @extend .text;
  font-size: 96px;
  font-weight: 100;

  @include respond-to-max($tabletLandscape) {
    font-size: 96px * 0.75;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 64px;
  }
}

.heading-h2 {
  @extend .text;
  font-size: 64px;
  font-weight: 100;

  @include respond-to-max($tabletLandscape) {
    font-size: 64px * 0.75;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 36px;
  }
}

.heading-h3 {
  @extend .text;
  font-size: 48px;
  font-weight: 100;

  @include respond-to-max($tabletLandscape) {
    font-size: 48px * 0.75;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 26px;
  }
}

.heading-h4 {
  @extend .text;
  font-size: 36px;
  font-weight: 100;

  @include respond-to-max($tabletLandscape) {
    font-size: 36px * 0.75;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 24px;
  }
}

.heading-h5 {
  @extend .text;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;

  @include respond-to-max($tabletLandscape) {
    font-size: 24px * 0.75;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 18px;
  }
}

.hrc-lead {
  @extend .text;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.38em;

  @include respond-to-max($tabletLandscape) {
    font-size: 28px * 0.75;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 18px;
  }
}

.hrc-regular {
  @extend .text;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;

  @include respond-to-max($tabletLandscape) {
    font-size: 16px;
  }

  @include respond-to-max($mobileLandscape) {
    font-size: 14px;
  }
}

.hrc-small {
  @extend .text;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;

  @include respond-to-max($mobileLandscape) {
    font-size: 13px;
  }
}

a {
  text-decoration: none;
  transition: opacity 0.2s,
              color 0.2s,
              background 0.2s,
              transform 0.2s,
              border 0.2s;
}