@import "../mixins/mixins";
@import "../commons/variables";

/* Arrows */
.swiper-button {
  &-prev,
  &-next {
    position: absolute;
    top: calc(50% - 34px);
    width: 68px;
    height: 68px;
    color: #ffffff;
    font-size: 24px;
    opacity: 0.9;
    background-color: $redActiveColor;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 30px;
    transform: translate3d(0, 0, 1px);
    z-index: 10;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &.swiper-button-disabled {
      opacity: 0.2;
      cursor: auto;
      pointer-events: none;
    }
  }
  &-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 40px;
    right: auto;
    transform: translate3d(0, 0, 1px) rotateY(180deg);

    @include respond-to-max(1100px) {
      left: 0;
    }
  }

  &-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 40px;
    left: auto;

    @include respond-to-max(1100px) {
      right: 0;
    }
  }
}

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  transition: 300ms;
  transform: translate3d(0,0,0);
  z-index: 10;

  &.swiper-pagination-hidden {
    opacity: 0;
  }
  /* Common Styles */
  &-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  /* Bullets */
  &-bullet {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 0 6px;
    background: $grayish;

    .swiper-pagination-clickable & {
      cursor: pointer;
    }
    &-active {
      background: $redActiveColor;
    }
  }
}