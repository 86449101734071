@import "../mixins/mixins";
@import "../commons/variables";

.reference {
  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;

    &:after {
      @include after-or-before-over-block;
      background: rgba(27, 32, 55, 0.5);
      transition: opacity 0.4s;
    }

    &:hover {

      .reference__poster {
        transform: scale(1.05);
      }

      &:after {
        opacity: 0.5;
      }
    }
  }

  &__poster {
    flex: 1;
    width: 100%;
    background-color: #cecece;
    background-image: $icon-logo-mobile;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 0.4s;
  }
  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 60px;
    z-index: 2;

    @include respond-to-max(1600px) {
      padding: 20px;
    }

    &:after {
      @include after-or-before-over-block;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
      z-index: -1;
    }

    .hrc-lead {
      margin-bottom: 0.5em;
      max-width: 480px;
      color: #ffffff;
      line-height: 1.28em;
    }
    .hrc-regular {
      color: #CAD1F4;
    }
  }
  &__tag {
    //margin-bottom: 25px;
    margin-bottom: 1em;
  }
}