@import "../commons/variables";
@import "../mixins/mixins";

.cut-icon {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  overflow: hidden;
  flex: 1;
  margin: 0 5px;
  line-height: 1em;

  [class*="icon-"] {
    display: block;
    flex: 1 0 auto;
    padding: 1px 10px;
    text-align: center;
    line-height: inherit;
  }
}

.side_left {
  direction: rtl;
}