@import '../mixins/mixins';
@import '../commons/variables';

.enter-input {
  display: flex;
  align-items: center;
  margin-top: 40px;

  &__wrap {
    display: flex;
    padding-left: 60px;
  }

  &__btn {
    height: 60px;
    width: 60px;
    font-size: 24px;
    font-weight: 700;
    color: #8D99AE;
    cursor: pointer;
    border: 2px solid #8D99AE;
    transition: background 0.2s ease;

    &:hover {
      background-color: darken(#FFFFFF, 10%);
    }
  }

  &__mask {
    margin: 0 20px;
    padding: 0 30px;
    font-size: 18px;
    width: 140px;
    color: $darkColor;
    text-align: center;
    background: transparent;
    outline: none;
    border-bottom: 2px solid #8D99AE;
  }
}