@import "../mixins/mixins";
@import "../commons/variables";

@import "../commons/variables-graphics";
@import "../partials/sublist-catalogue";

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  z-index: 100;

  &:after {
    @include respond-to-max($menuMobilePoint) {
      display: block;
      position: fixed;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      opacity: 0;
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
      transition: opacity 0.2s;
    }
  }
}

.b-header {
  display: flex;
  justify-content: space-between;
  background-color: $headerBgColor;

  &__left,
  &__right {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    direction: ltr;
    transform: translate3d(0, 0, 0);
  }

  &__search {
    position: relative;
    .icon-search {
      background-size: 20px auto;
    }
    input {
      font-size: 18px;
    }
  }

  &__language {
    display: flex;
    flex: 0 0 auto;
    padding-right: 40px;
    align-items: center;
    font-size: 18px;

    @include respond-to-max(1110px) {
      padding-right: 20px;
    }

    li {
      display: block;
      height: 48px;
      width: 48px;
      cursor: auto;

      a {
        display: block;
        height: 100%;
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        color: #e0e0e0;
        line-height: 48px;
        background-color: $darkColor;

        &:hover {
          background-color: lighten($darkColor, 5%);
        }
      }

      a.current-select {
        pointer-events: none;
        color: $darkColor;
        background-color: $grayish;
      }
    }
  }

  .result-view {
    position: absolute;
    top: 48px;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    font-size: 16px;
    line-height: 2em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

    .heading-h5 {
      padding: 10px;
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid #cecece;
    }

    li {
      padding: 5px 5px 5px 30px;
      transition: background 0.2s;

      &:hover {
        background-color: darken(#FFFFFF, 10%);
      }

      a {
        line-height: 1.8em;
        font-size: 14px;
        display: block;
        color: $darkColor;
      }
    }
  }
}

.header-state__desktop {
  &.b-header {
    height: $heightHeader;
    max-height: $maxHeightHeader;
    transition: height 0.4s;

    @include respond-to-max(1350px) {
      height: $heightHeader * 0.75;
    }

    @include respond-to-max($menuMobilePoint) {
      display: none;
    }
  }

  .b-header {
    &__logo {
      position: absolute;
      top: 0;
      left: 0;
      width: $heightHeader;
      max-width: $maxHeightHeader;
      z-index: 2;
      transition: width 0.4s;

      @include respond-to-max(1350px) {
        flex: 0 0 $heightHeader * 0.75;
        width: $heightHeader * 0.75;
      }
    }

    &__float {
      display: flex;
      justify-content: space-between;
      direction: rtl;
      width: 100%;
      padding-left: $heightHeader;

      @include respond-to-max(1350px) {
        padding-left: $heightHeader * 0.75;
      }
    }

    &__left {
      order: 1;
    }
    &__right {
      position: relative;
      direction: ltr;
    }

    &__nav-list {
      position: relative;
      display: flex;
      padding-left: 20px;
      transform-origin: left center;
      transition: opacity 0.2s, transform 0.4s;
      z-index: 0;

      @include respond-to-max(1110px) {
        padding-left: 10px;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: '';
        height: 100%;
        width: 100%;
        pointer-events: none;
        filter: blur(1px);
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
    &__nav-item {
      flex: 0 0 auto;
    }
    &__nav-link {
      margin: 0 20px;
      font-size: 18px;
      font-weight: 700;
      color: $darkColor;
      text-transform: uppercase;

      @include respond-to-max(1350px) {
        margin: 0 15px;
        font-size: 16px;
      }

      @include respond-to-max(1100px) {
        margin: 0 7px;
      }

      &.active-link {
        color: $grayish;
        pointer-events: none;
      }

      &:hover {
        color: lighten($darkColor, 20%);
      }
    }

    &__overlay {
      display: flex;
    }

    &__search,
    &__message,
    &__phone {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      font-size: 18px;
      background-color: $darkColor;
    }

    &__search,
    &__phone {
      margin-right: 20px;
    }

    &__button-open {
      display: block;
      height: 48px;
      flex: 0 0 48px;
      width: 48px;
      font-size: inherit;
      background-color: $darkColor;
      background-size: 1em auto;

      &:hover {
        background-color: lighten($darkColor, 5%);
      }
    }

    &__message {
      margin-right: 5px;

      .icon-message {
        background-size: 24px auto;
      }
    }

    &__hide-block {
      align-items: center;
      height: 100%;
      width: 0;
      flex: 0 0 auto;
      line-height: 48px;
      overflow: hidden;

      .b-header__hide-area {
        white-space: nowrap;
        margin-right: 15px;
        margin-left: 5px;
        color: #e0e0e0;

        &.hrc-input__field {
          width: 400px;
        }
      }

      a:hover {
        color: lighten(#e0e0e0, 20%);
      }
    }
  }
}

.header-state__mobile {

  &.b-header {
    display: none;
    height: $tabletHeader;
    justify-content: flex-end;

    @include respond-to-max($menuMobilePoint) {
      display: flex;
    }

    @include respond-to-max($mobileLandscape) {
      height: $mobileHeader;
    }

    .result-view {
      top: 60px + $tabletHeader;
      background-color: #ffffff;
      right: 0;
      max-height: calc(100vh - 96px);
      width: 100vw;

      @include respond-to-max($mobileLandscape) {
        top: 48px + $mobileHeader;
      }
    }

    .b-header {
      &__burger {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: $tabletHeader;
        width: $tabletHeader;
        padding: (($tabletHeader - 24px) / 2) (($tabletHeader - 28px) / 2);
        background-color: $darkColor;
        transform: translate3d($tabletHeader, 0, 0);
        z-index: 4;
        transition: transform 0.2s;

        @include respond-to-max($mobileLandscape) {
          height: $mobileHeader;
          width: $mobileHeader;
          padding: 12px 10px;
          transform: translate3d($mobileHeader, 0, 0);
        }

        &:after,
        &:before {
          content: '';
          transform-origin: 0 50%;
          transition: transform 0.2s;
        }

        span,
        &:after,
        &:before {
          display: block;
          height: 2px;
          background-color: #ffffff;
        }

        span {
          margin: 10px 0;
          transition: 0.2s;
        }
      }

      &__left {
        position: absolute;
        flex-direction: column;
        justify-content: initial;
        align-items: initial;
        top: 0;
        left: 0;
        width: 280px;
        height: 100vh;
        max-height: 100vh;
        background-color: $darkColor;
        z-index: 3;
        transform: translate3d(-100%, 0, 0);
        transition: 0.2s;

        .icon-logo-mobile {
          height: 60px;
          width: 60px;
          flex: 0 0 auto;
          padding: 0;
        }
      }

      &__nav-list {
        padding: 20px 0;
      }

      &__nav-item {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(138, 91, 72, 0.3);

        &:first-child {
          border-top: 1px solid rgba(138, 91, 72, 0.3);
        }

        .b-header__nav-link {
          margin: 20px 0;
          font-size: 18px;
          font-weight: 700;
          color: #ffffff;
          text-transform: uppercase;
        }


        &.icons-here {

          .b-header__nav-link {
            flex: 0 0 calc(100% - 50px);
            max-width: calc(100% - 50px);
          }
        }

        button {
          display: block;
          width: 40px;
          margin-left: auto;

          i {
            font-size: 18px;
            font-weight: bold;
            color: #BE7355;
            pointer-events: none;
          }
        }
      }

      &__right {
        z-index: 2;
      }

      &__search {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        margin-right: 8px;
        height: $tabletHeader * 0.75;
        font-size: 16px;
        background-color: $darkColor;

        @include respond-to-max($mobileLandscape) {
          height: $mobileHeader * 0.667;
        }
      }

      &__button-open {
        display: block;
        height: $tabletHeader * 0.75;
        flex: 0 0 $tabletHeader * 0.75;
        width: $tabletHeader * 0.75;
        font-size: inherit;
        background-color: $darkColor;
        background-size: 1em auto;

        @include respond-to-max($mobileLandscape) {
          height: $mobileHeader * 0.667;
          flex: 0 0 $mobileHeader * 0.667;
          width: $mobileHeader * 0.667;
        }

        &:hover {
          background-color: lighten($darkColor, 5%);
        }
      }

      &__contact {
        p {
          display: flex;
          align-items: center;
          padding: 15px 0;
        }

        i {
          display: block;
          height: 20px;
          width: 45px;
          background-position: center;
        }

        .icon-message_orange {
          background-image: $icon-message_orange;
        }

        .icon-phone_orange {
          background-image: $icon-phone_orange;
        }

        a {
          color: #be7355;
        }
      }

      &__message {

      }

      &__language {
        padding-right: 8px;
        font-size: 14px;

        li {
          height: $tabletHeader * 0.75;
          width: $tabletHeader * 0.75;

          @include respond-to-max($mobileLandscape) {
            height: $mobileHeader * 0.667;
            flex: 0 0 $mobileHeader * 0.667;
            width: $mobileHeader * 0.667;
          }

          a {
            line-height: $tabletHeader * 0.75;

            @include respond-to-max($mobileLandscape) {
              line-height: $mobileHeader * 0.667;
            }
          }
        }
      }

      &__hide-block {
        position: fixed;
        top: $tabletHeader;
        left: 0;
        right: 0;
        padding: 8px;
        height: $tabletHeader;
        background-color: $headerBgColor;
        //transform: translate3d(0, -100%, 0);
        transform-origin: 50% 0;
        transform: scale(1, 0);
        transition: transform 0.2s;
        z-index: -1;

        @include respond-to-max($mobileLandscape) {
          top: $mobileHeader;
          height: $mobileHeader;
        }

        input {
          padding: 0 10px;
          white-space: nowrap;
          color: #e0e0e0;
          width: 100%;
        }
      }

      &__menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: $darkColor;
      }

      &__submenu {
        padding: 60px 20px;
        transform: translate3d(-100%, 0, 0);
        transition: 0.2s;

        button {
          display: flex;
          align-items: center;
          color: #BE7355;
          font-size: 18px;

          span {
            display: block;
            line-height: 1;
          }

          i {
            display: block;
            padding-right: 10px;
            font-size: 0.9em;
            font-weight: 700;

            &:before {
              display: block;
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
  }

  .action__toggle-sublist {
    &:after,
    &:before {
      background-color: #BE7355;
    }
  }

  .hidden-sublist {
    li  {
      padding-left: 20px;
      margin-bottom: 20px;

      a {
        font-size: 18px;
        color: #ececec;
      }
    }
  }
}

.open--mobile-menu {
  &:after {
    opacity: 1;
    pointer-events: auto;
  }

  .header-state__mobile {
    .b-header {
      &__burger {
        transform: translate3d(0, 0, 0);
        background-color: $darkColor !important;

        span {
          opacity: 0;
        }

        &:after {
          background-color: #9497A6;
          transform: rotate(-45deg) scale(1.21);
        }
        &:before {
          background-color: #9497A6;
          transform: rotate(45deg) scale(1.21);
        }
      }

      &__left {
        transform: translate3d(0, 0, 0);
        overflow-y: auto;
      }
    }
  }
}

.open--mobile-submenu {
  &.open--mobile-menu {
    .b-header {
      &__menu-main {
        //opacity: 0;
        //pointer-events: none;
        //transition-delay: 0.1s;

        display: none;
      }
      &__submenu {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.l-main {
  padding-top: $heightHeader;

  @include respond-to-max(1350px) {
    padding-top: $heightHeader * 0.75;
  }

  @include respond-to-max($menuMobilePoint) {
    padding-top: $tabletHeader;
  }

  @include respond-to-max($mobileLandscape) {
    padding-top: $mobileHeader;
  }
}

.small-header {
  height: $smallHeightHeader;

  .b-header {
    height: $smallHeightHeader;


    &__logo {
      width: $smallHeightHeader;
      max-width: $maxHeightHeader;
      z-index: 2;
    }
  }
}

.small-header + .l-main {
  padding-top: $smallHeightHeader;
}

.float-panel {
  .b-header__nav-list {

    @include respond-to-max(1765px) {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .header-state__mobile {
    @include respond-to-max($menuMobilePoint) {
      .b-header__hide-block {
        //transform: translate3d(0, 0, 0);
        transform: scale(1, 1);
      }
    }
  }
}
