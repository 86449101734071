@import "../mixins/mixins";
@import "../commons/variables";

.bounding-wrapper {
  width: 100%;
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;

  //@include respond-to-max($maxWidth + 39px) {
  //  padding-left: 10px;
  //  padding-right: 10px;
  //}
  //
  //@include respond-to-max($maxWidth + 19px) {
  //  padding-left: 20px;
  //  padding-right: 20px;
  //}
}